import {
  BaseDataListResponse,
  DetectDevice,
  Project,
  ProjectReview,
  TopMembers,
  StormComment,
  ListFollowProject,
  ListFileProjectReview,
  IReviewItem,
} from "Models";
import {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { getListReviews } from "@/api/endpoint/reviews/get-list";
import { PAGE_SIZE_REVIEWS } from "@/constants/number";
import { CommentTypeEnum } from "@/constants/enum/comment";

interface IHomeContext extends DetectDevice {
  listProject?: BaseDataListResponse<Project>;
  listReviews?: BaseDataListResponse<ProjectReview>;
  listTopMember?: TopMembers[];
  listStormComment?: BaseDataListResponse<StormComment>;
  listFollowProject?: ListFollowProject;
  activeTab?: string;
  setActiveTab?: any;
  handleFetchMoreData?: any;
  loading?: boolean;
  handleFetchData?: any;
  currentPage?: number;
  setCurrentPage?: any;
  listTopAnimation?: {
    results: {
      _id: string;
      project: {
        _id: string;
        title: string;
      };
      comment: string;
      nickName?: string;
      type: CommentTypeEnum;
      createdAt: string;
      publishedAt?: string;
      label?: string;
    }[];
  };
  selectedViewFiles?: {
    currentFile: ListFileProjectReview;
    files: ListFileProjectReview[];
    maxFileShow?: number;
  };

  setSelectedViewFiles?: Dispatch<
    SetStateAction<{
      currentFile: ListFileProjectReview;
      files: ListFileProjectReview[];
      maxFileShow?: number;
    }>
  >;

  setListFollowProject?: Dispatch<SetStateAction<ListFollowProject>>;
}

interface HomeContextProviderProps extends IHomeContext {
  children: React.ReactNode;
}

export const HomeContext = createContext<IHomeContext>({
  listProject: {
    page: 1,
    totalPages: 0,
    results: [],
  },
});

export function HomeContextProvider(
  props: HomeContextProviderProps
): JSX.Element {
  const { children, ...rest } = props;
  const [activeTab, setActiveTab] = useState("lastest");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [listReviews, setListReviews] = useState<
    BaseDataListResponse<ProjectReview>
  >({ ...props.listReviews });

  let params = {
    limit: PAGE_SIZE_REVIEWS,
    page: 1,
  };

  const handleFetchData = async (tab: string) => {
    setLoading(true);
    setListReviews({});
    setCurrentPage(1);
    const result: BaseDataListResponse<ProjectReview> = await getListReviews(
      tab,
      params
    );
    if (result) {
      setListReviews(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    setListReviews(props.listReviews);
  }, [props.listReviews]);

  const [selectedViewFiles, setSelectedViewFiles] = useState<{
    currentFile: ListFileProjectReview;
    files: ListFileProjectReview[];
  }>(null);

  const [listFollowProject, setListFollowProject] = useState(
    rest.listFollowProject
  );

  return (
    <HomeContext.Provider
      value={{
        ...rest,
        activeTab,
        setActiveTab,
        listReviews,
        loading,
        handleFetchData,
        currentPage,
        setCurrentPage,
        selectedViewFiles,
        setSelectedViewFiles,
        listFollowProject,
        setListFollowProject,
      }}>
      {props.children}
    </HomeContext.Provider>
  );
}
