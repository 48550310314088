import { ValueLocation } from "@/models/location";
import clsx from "clsx";
import { useState } from "react";
import PopupSelectLocation from "./PopupSelectLocation";

/* eslint-disable @next/next/no-img-element */
const LocationSelect = ({
  onChange,
}: {
  onChange?: (value: ValueLocation) => void;
}) => {
  const [activeTab, setActiveTab] = useState<string>(null);
  const [valueLocation, setValueLocation] = useState<ValueLocation>({
    city: [],
    district: [],
    ward: [],
  });

  return (
    <>
      <div className="px-4 ">
        <div className="text-fs-14 font-medium mb-3">
          Tìm kiếm trong khu vực
        </div>
        <div
          className="w-full border  px-2 py-1.5 rounded border-grey-400 flex items-center justify-between mb-3 cursor-pointer"
          onClick={() => {
            setActiveTab("city");
          }}>
          {valueLocation.city?.length
            ? valueLocation.city.map((e) => e.name).join(", ")
            : "Tỉnh/Thành phố"}
          <i className="mrv mrv-keyboard_arrow_down"></i>
        </div>
        <div className="flex gap-4 w-full">
          <div
            className={clsx(
              "w-full border  px-2 py-1.5 rounded  flex items-center justify-between cursor-pointer ",
              valueLocation?.city.length
                ? "border-grey-400"
                : "border-grey-300 text-grey-400"
            )}
            onClick={() => {
              valueLocation?.city.length && setActiveTab("district");
            }}>
            <div className="flex-1 line-clamp-1">
              {valueLocation.district?.length
                ? valueLocation.district.map((e) => e.name).join(", ")
                : " Quận/Huyện"}
            </div>

            <i className="mrv mrv-keyboard_arrow_down"></i>
          </div>
          <div
            className={clsx(
              "w-full border  px-2 py-1.5 rounded  flex items-center justify-between cursor-pointer line-clamp-1",
              valueLocation?.district.length
                ? "border-grey-400"
                : "border-grey-300 text-grey-400 "
            )}
            onClick={() => {
              valueLocation?.district.length && setActiveTab("ward");
            }}>
            <div className="flex-1 line-clamp-1">
              {valueLocation.ward?.length
                ? valueLocation.ward.map((e) => e.name).join(", ")
                : "  Phường/Xã"}
            </div>
            <i className="mrv mrv-keyboard_arrow_down"></i>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "absolute duration-300 bg-[rgba(0,0,0,0.2)] w-screen h-screen top-0",
          activeTab ? "opacity-100 z30" : "opacity-0 -z-30"
        )}
        onClick={() => setActiveTab(null)}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={clsx(
            "absolute h-fit w-full bg-white rounded-t-[1.5rem] duration-300",
            activeTab ? "bottom-0 " : "-bottom-full"
          )}>
          <PopupSelectLocation
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            valueLocation={valueLocation}
            setValueLocation={(value) => {
              setValueLocation(value);
              onChange && onChange(value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LocationSelect;
