import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import SeenProjectCard from "./SeenProjectCard";

const SeenProjects = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userDataHeader } = useAuth();
  const getData = useCallback(async () => {
    if (userDataHeader)
      try {
        setLoading(true);
        const res = await axios.request({
          baseURL: MEEY_REVIEW_API_URL,
          url: "/v1/user/topviewed",
          headers: {
            ...userDataHeader,
          },
        });
        setData(res.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
  }, [userDataHeader]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      {loading ? (
        <>
          {" "}
          <div className="uppercase text-grey-700 text-fs-14 font-semibold px-3 mt-5">
            <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
              <div className="flex items-center w-full space-x-2">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
            </div>
          </div>
          <div className="w-full flex md:flex-col  gap-3 md:bg-white rounded-lg md:max-h-[726px] overflow-auto pt-2 px-3 no-scrollbar">
            <div className="h-[180px] w-full">
              <div className="flex items-center w-full space-x-2 mt-2">
                <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <div className="flex items-center w-full space-x-2 mt-2">
                <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                <div className="h-10 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
            </div>
          </div>
        </>
      ) : data && data?.length ? (
        <>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold px-3 mt-5">
            🏢 dự án đã xem
          </div>
          <div className="w-full flex md:flex-col  gap-3 md:bg-white rounded-lg md:max-h-[726px] overflow-auto pt-2 px-3 no-scrollbar">
            {data.map((item) => (
              <SeenProjectCard key={item?._id} item={item} />
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default SeenProjects;
