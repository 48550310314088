import parse from "html-react-parser";

export const parseStringToHtml = (str: string) => {
  if (!str || str.toString().length === 0) {
    return null;
  }

  const stringHtml = removeErrorCharacter(str.toString());
  return parse(stringHtml, {});
};

export const removeErrorCharacter = (str: string) => {
  return str.replaceAll("atarget", "a target");
};

export const exactCommentString = (
  content,
  mentions: Array<{ userId: string; nickName?: string }>
) => {
  let rawContentString = content;

  mentions?.forEach((mention) => {
    rawContentString = rawContentString
      .replaceAll(
        `@{{${mention.userId}||${mention.nickName}}}`,
        `@${mention.nickName}`
      )
      .replaceAll(`@{{${mention.userId}||anymos}}`, `@Ẩn danh`);
  });
  return rawContentString;
};
