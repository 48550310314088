import { getListSearchHistory } from "@/api/endpoint/search/get-list-search-history";
import { saveSearchHistory } from "@/api/endpoint/search/save-search-history";
import { createFetcher } from "@/api/utils/fetch-api";
import AvatarWithName from "@/components/AvatarWithName";
import Button from "@/components/Button";
import {
  MAX_LENGTH_SEARCH_INPUT,
  TIMEOUT_LOAD_MORE,
} from "@/constants/common-constant";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import { NOT_FOUND_PROJECT_IMAGE } from "@/constants/image";
import { DEFAULT_LOGO_PROJECT } from "@/constants/project-constant";
import { useAnimateState } from "@/hooks/useAnimateState";
import { useAuth } from "@/hooks/useAuth";
import { ValueLocation } from "@/models/location";
import { convertAddress } from "@/utils";
import { BaseDataListResponse, BaseItemResponseTranslate } from "Models";
import clsx from "clsx";
import unionBy from "lodash/unionBy";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LocationSelect from "./LocationSelect";

export interface BaseValue {
  _id: string;
  [key: string]: string;
}

let timeOutSearch: NodeJS.Timeout = null;

// eslint-disable-next-line react/display-name
const SearchProject = ({
  onClose,
  open,
}: {
  showSuffixIcon?: boolean;
  className?: string;
  allowClear?: boolean;
  onClose?: () => void;
  open?: boolean;
}) => {
  const [response, setResponse] = useState<BaseDataListResponse<any>>({
    limit: 30,
    page: 1,
    totalPages: 0,
    totalResults: 0,
    results: [],
  });

  const { push, query } = useRouter();

  const [valueInput, setValueInput] = useState<any>("");
  const [valueLocation, setValueLocation] = useState<ValueLocation>({
    city: [],
    district: [],
    ward: [],
  });

  const listDataOptionsRender = useMemo(() => {
    if (!valueInput) {
      return [];
    } else {
      return response.results;
    }
  }, [response.results, valueInput]);

  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  const [openDropdown, setDropDown] = useState(false);
  const { trigger, triggerClose } = useAnimateState({
    open: openDropdown,
    handleClose: () => {
      setDropDown(false);
    },
  });

  const { userDataHeader } = useAuth();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.height = "100vh";
    } else document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
      document.body.style.position = "relative";
    };
  }, [open]);

  const handleLoadMore = async () => {
    try {
      const dataPath = `v1/projects/search`;
      const res = await createFetcher<
        BaseDataListResponse<BaseItemResponseTranslate>
      >(() => ({
        apiUrl: MEEY_REVIEW_API_URL,
      }))(
        "POST",
        dataPath,
        {
          sort: "title:asc",
          title: valueInput || "",
          page: response.page + 1,
          limit: response.limit,
        },
        { ...userDataHeader }
      );

      setResponse({
        ...res,
        results: unionBy(
          response.results,
          res.results.map((item) => item),
          "_id"
        ),
      });
    } catch (error) {
    } finally {
    }
  };

  const loadData = async (params: string) => {
    setLoading(true);
    const dataPath = `v1/projects/search`;
    try {
      const res = await createFetcher<
        BaseDataListResponse<BaseItemResponseTranslate>
      >(() => ({
        apiUrl: MEEY_REVIEW_API_URL,
      }))(
        "POST",
        dataPath,
        {
          title: params,
          sort: "title:asc",
          page: 1,
          limit: response.limit,
        },
        { ...userDataHeader }
      );

      setResponse(
        res.totalResults
          ? {
              ...res,
              results: res.results,
            }
          : {
              page: 1,
              totalResults: 1,
              totalPages: 1,
              results: [],
            }
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (params?: string) => {
    setValueInput(params?.trim() ? params : "");

    if (params?.length || (!params?.length && valueInput?.length)) {
      setTyping(true);
      if (timeOutSearch) clearTimeout(timeOutSearch);
      timeOutSearch = setTimeout(() => {
        setTyping(false);
        loadData(params);
      }, TIMEOUT_LOAD_MORE);
    }
  };

  useEffect(() => {
    if (query?.title) {
      setValueInput(query?.title || "");
    }
  }, [query]);

  const handleGetListSearchHistory = async () => {
    setLoading(true);
    const res = await getListSearchHistory();
    if (res) {
      setSearchHistory(res);
    }
    setLoading(false);
  };

  const handleSaveSearchHistory = async (project?: any) => {
    await saveSearchHistory(
      {
        keywords: project
          ? project?.title || project?.keywords
          : valueInput?.trim(),
        project: project?._id,
      },
      userDataHeader
    );
  };

  return (
    <div className="fixed top-0 !z-50 left-0 w-screen h-screen bg-white flex flex-col">
      <div className="flex justify-between px-3 py-4">
        <div className="font-medium text-fs-18 ">Tìm kiếm dự án</div>
        <div onClick={onClose} className="cursor-pointer">
          <i className="mrv mrv-close text-fs-24"></i>
        </div>
      </div>
      <div className="flex items-center w-full px-4 py-2 gap-2.5 mb-4 flex-0">
        <div className=" border px-2 py-1.5 rounded border-grey-400 flex  w-full relative">
          {<i className="mrv mrv-search text-fs-20 text-grey-500"></i>}
          <input
            placeholder="Tìm dự án mà bạn quan tâm..."
            value={valueInput}
            className="w-full text-grey-900 text-base"
            onChange={(e) => {
              handleSearch(e?.target?.value?.slice(0, MAX_LENGTH_SEARCH_INPUT));
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter")
                if (valueInput?.trim()) {
                  handleSaveSearchHistory();
                  push({
                    pathname: "/search",
                    query: {
                      title: valueInput,
                      city: valueLocation.city?.map((e) => e._id),
                      district: valueLocation.district?.map((e) => e._id),
                      ward: valueLocation.ward?.map((e) => e._id),
                    },
                  });
                }
              // onClose()
            }}
            onBlur={async () => {
              await Promise.resolve();
              triggerClose();
            }}
            autoFocus={true}
            onFocus={() => {
              setDropDown(true);
              handleGetListSearchHistory();
            }}
          />
          {valueInput?.length ? (
            <div
              className="z-40 absolute h-4 w-4 rounded-full bg-grey-500 flex items-center justify-center right-2 top-3 cursor-pointer"
              onClick={() => {
                setValueInput("");
              }}>
              <i className="mrv mrv-close text-fs-12 text-white"></i>
            </div>
          ) : null}
        </div>

        {openDropdown ? (
          <div
            onClick={triggerClose}
            className={clsx(
              "duration-300",
              trigger ? "w-fit" : "w-0 overflow-hidden"
            )}>
            <i className={clsx("mrv mrv-close text-fs-24 cursor-pointer")}></i>
          </div>
        ) : null}
      </div>
      <LocationSelect
        onChange={(value) => {
          setValueLocation(value);
        }}
      />

      {openDropdown ? (
        <div
          className={clsx(
            "absolute top-[120px] h-full overflow-auto bg-white w-full flex pt-2 duration-300",
            trigger ? "opacity-100" : "opacity-0"
          )}>
          {searchHistory?.length && !valueInput?.trim() ? (
            <div className="py-2 px-4 overflow-y-auto h-[calc(300px)] w-full">
              <div className="text-base text-grey-800 font-semibold mb-2">
                Lịch sử tìm kiếm gần đây
              </div>
              {searchHistory.map((item) => {
                return (
                  <div key={item._id} className="py-2">
                    {item?.project ? (
                      <Link
                        href={`/project/${item.project.slug}-${
                          item.project.shortId ? item.project.shortId : item._id
                        }`}
                        onClick={() => {
                          handleSaveSearchHistory(item?.project);
                        }}>
                        <AvatarWithName
                          name={item?.project.title}
                          textClass="text-fs-14 font-medium text-grey-800"
                          subTitleClass="text-fs-12 font-normal text-grey-500"
                          subTitle={convertAddress(item?.project)}
                          src={
                            item?.project.logo?.uri ??
                            `/${DEFAULT_LOGO_PROJECT}`
                          }
                          imageClass="!object-contain"
                          imageProps={{
                            resizeOnFly: item?.project.logo?.uri ? true : false,
                            resizeOptions: {
                              width: 0,
                              height: 60,
                            },
                          }}
                        />
                      </Link>
                    ) : (
                      <div
                        onClick={() => {
                          handleSaveSearchHistory({
                            keywords: item?.keywords,
                          });
                          push({
                            pathname: "/search",
                            query: {
                              title: item?.keywords,
                              city: valueLocation.city?.map((e) => e._id),
                              district: valueLocation.district?.map(
                                (e) => e._id
                              ),
                              ward: valueLocation.ward?.map((e) => e._id),
                            },
                          });
                        }}>
                        <div className="flex items-center gap-3">
                          <div className="w-9 h-6 shrink-0 flex justify-center">
                            <img
                              src="/images/timer.svg"
                              alt="time"
                              className="w-6 h-6"
                            />
                          </div>
                          <span className="text-base text-grey-800 font-medium">
                            {item?.keywords}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : valueInput?.trim() ? (
            loading || typing ? (
              <div className="flex justify-center h-4  absolute  w-full top-[100px] pt-1">
                <div className={"dot-flashing"}></div>
              </div>
            ) : (
              <div
                className="overflow-y-auto h-[calc(300px)] w-full"
                id="scrollableDiv">
                {listDataOptionsRender?.length ? (
                  <InfiniteScroll
                    dataLength={listDataOptionsRender?.length}
                    next={handleLoadMore}
                    hasMore={
                      listDataOptionsRender?.length < response.totalResults
                    }
                    loader={
                      <div className="px-4 flex items-center justify-center">
                        <div className="dot-flashing" />
                      </div>
                    }
                    scrollableTarget="scrollableDiv">
                    {listDataOptionsRender.map((item, index) => (
                      <div
                        key={item._id}
                        title={item.title}
                        className="px-4 py-2">
                        <Link
                          href={`/project/${item.slug}-${
                            item.shortId ? item.shortId : item._id
                          }`}
                          onClick={() => handleSaveSearchHistory(item)}>
                          <AvatarWithName
                            name={item.title}
                            textClass="text-fs-14 font-medium text-grey-800"
                            subTitleClass="text-fs-12 font-normal text-grey-500"
                            subTitle={convertAddress(item)}
                            src={item.logo?.uri ?? DEFAULT_LOGO_PROJECT}
                            imageProps={{
                              resizeOnFly: item.logo?.uri ? true : false,
                            }}
                          />
                        </Link>
                      </div>
                    ))}
                  </InfiniteScroll>
                ) : (
                  <div className="flex flex-col justify-center items-center h-[221px]">
                    <img
                      src={NOT_FOUND_PROJECT_IMAGE}
                      alt="not-found-project"
                      className="mb-2"
                    />
                    <span className="text-fs-14 text-grey-500">
                      Không có dự án nào phù hợp với từ khóa
                    </span>
                    <span className="text-grey-700 text-base font-semibold w-full px-4 break-all text-center">
                      {valueInput}
                    </span>
                  </div>
                )}
              </div>
            )
          ) : null}
        </div>
      ) : null}
      <div className="flex-[1_1_auto] flex items-end w-full px-4">
        <Button
          className="w-full flex justify-center mb-6 "
          btnType="filled"
          onClick={() => {
            push({
              pathname: "/search",
              query: {
                title: valueInput,
                city: valueLocation.city?.map((e) => e._id),
                district: valueLocation.district?.map((e) => e._id),
                ward: valueLocation.ward?.map((e) => e._id),
              },
            });
          }}>
          {" "}
          <i className="mrv mrv-search"></i> Tìm kiếm
        </Button>
      </div>
    </div>
  );
};
export default SearchProject;
