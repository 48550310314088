import Button from "@/components/Button";
import MeeyImage from "@/components/MeeyImage";
import DocViewerPreview from "@/components/ReviewInputComponent.tsx/DocViewerPreview";
import VideoPlayer from "@/components/VideoPlayer";
import { fileType } from "@/constants/file";
import { HomeContext } from "@/context/home.context";
import { resizeOnFlyUrl } from "@/utils";
import clsx from "clsx";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ModalViewDetailFileFollow = () => {
  const { selectedViewFiles, setSelectedViewFiles } = useContext(HomeContext);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const currentIndexFile = useMemo(() => {
    return selectedViewFiles?.files.findIndex(
      (item) => item._id == selectedViewFiles?.currentFile?._id
    );
  }, [selectedViewFiles]);

  return mounted ? (
    <>
      {selectedViewFiles?.currentFile ? (
        <div className="fixed top-0 z-40 left-0 w-screen h-screen bg-black-1000  overflow-hidden  custom-modal-preview-file flex flex-col flex-[1_auto_1] gap-2 p-4">
          <TransformWrapper
            initialScale={1}
            disabled={
              !selectedViewFiles?.currentFile.name.match(fileType.image)
            }>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                {/* header */}
                <div className="flex items-center justify-between">
                  <i
                    className="mrv mrv-close text-white text-fs-32 leading-[2rem] cursor-pointer"
                    onClick={() => {
                      setSelectedViewFiles(null);
                    }}></i>
                </div>
                {/*end header */}

                <div className="h-[80vh] w-full flex justify-between items-center gap-6">
                  <button
                    onClick={() => {
                      if (currentIndexFile != 0) {
                        resetTransform();
                        setSelectedViewFiles({
                          ...selectedViewFiles,
                          currentFile:
                            selectedViewFiles?.files[currentIndexFile - 1],
                        });
                      }
                    }}>
                    <i
                      className={clsx(
                        "mrv mrv-chevron_left  text-fs-32 leading-[32px]",
                        currentIndexFile == 0
                          ? "cursor-not-allowed text-grey-100"
                          : "text-white"
                      )}></i>
                  </button>
                  <div className="w-full h-full">
                    {selectedViewFiles?.currentFile.name.match(
                      fileType.image
                    ) ? (
                      <TransformComponent contentClass="!h-full !w-full flex justify-between items-center">
                        <div className="w-full h-full flex justify-center">
                          <img
                            src={
                              selectedViewFiles.currentFile.uri.match(
                                fileType.resizeImageSupport
                              )
                                ? resizeOnFlyUrl({
                                    uri: selectedViewFiles.currentFile.uri,
                                    width: 0,
                                    height: 0,
                                    gravity: "ce",
                                    resizingType: "auto",
                                  })
                                : selectedViewFiles.currentFile.url
                            }
                            alt="test"
                            className="w-[inherit] h-full absolute object-contain"
                          />
                        </div>
                      </TransformComponent>
                    ) : selectedViewFiles?.currentFile.name.match(
                        fileType.video
                      ) ? (
                      <TransformComponent contentClass="!h-full !w-full flex justify-between items-center">
                        <VideoPlayer
                          options={{
                            autoplay: true,
                            muted: true,
                            controls: true,
                            responsive: true,
                            fluid: true,
                            sources: [
                              {
                                src: selectedViewFiles?.currentFile.urlVod,
                              },
                            ],
                          }}
                          key={selectedViewFiles?.currentFile?.urlVod}
                        />
                      </TransformComponent>
                    ) : selectedViewFiles?.currentFile.name.match(
                        fileType.viewFileSupport
                      ) ? (
                      <TransformComponent contentClass="!h-full !w-full flex justify-between items-center">
                        <DocViewerPreview
                          url={selectedViewFiles?.currentFile.url}
                        />
                      </TransformComponent>
                    ) : (
                      <TransformComponent contentClass="!h-full !w-full flex justify-center items-center">
                        <div className="w-[685px] h-[196px] rounded-xl p-6 flex flex-col justify-center gap-4 bg-grey-100">
                          <div className="flex items-center gap-1">
                            <i className="mrv mrv-docs text-fs-36 text-grey-700"></i>
                            <div className="flex flex-col text-grey-700">
                              <span
                                className="text-base font-medium text-grey-800 line-clamp-1 break-words w-full"
                                title={selectedViewFiles?.currentFile.name}>
                                {selectedViewFiles?.currentFile.name}
                              </span>
                              <span className="text-fs-12">
                                {selectedViewFiles?.currentFile.size / 1000} MB
                              </span>
                            </div>
                          </div>
                          <div className="text-fs-20 text-grey-800 font-semibold">
                            Không thể xem trước tệp này. Vui lòng tải xuống để
                            xem nội dung
                          </div>
                          <a
                            href={selectedViewFiles?.currentFile?.url}
                            download
                            target="_blank"
                            className="flex justify-center w-full"
                            rel="noreferrer">
                            <Button className="meey-button meey-button-primary meey-button-filled py-2.5 px-4 h-11 flex items-center gap-1 w-fit ">
                              <i className="mrv mrv-download text-fs-20"></i>
                              <span className="text-fs-16 font-semibold">
                                Tải xuống
                              </span>
                            </Button>
                          </a>
                        </div>
                      </TransformComponent>
                    )}
                  </div>

                  <button
                    onClick={() => {
                      if (
                        currentIndexFile <
                        selectedViewFiles?.files?.length - 1
                      ) {
                        resetTransform();
                        setSelectedViewFiles({
                          ...selectedViewFiles,
                          currentFile:
                            selectedViewFiles?.files[currentIndexFile + 1],
                        });
                      }
                    }}>
                    <i
                      className={clsx(
                        "mrv mrv-chevron_right  text-fs-32 leading-[32px]",
                        currentIndexFile == selectedViewFiles?.files?.length - 1
                          ? "cursor-not-allowed text-grey-100"
                          : "text-white"
                      )}></i>
                  </button>
                </div>

                <div className="w-full flex justify-center">
                  <div className=" p-2 rounded-lg text-fs-12 text-grey-600 flex gap-2 flex-[0_0_auto] w-full md:max-w-[792px] overflow-x-auto">
                    {(selectedViewFiles.maxFileShow
                      ? selectedViewFiles?.files?.slice(
                          currentIndexFile,
                          currentIndexFile + selectedViewFiles.maxFileShow
                        )
                      : selectedViewFiles.files
                    ).map((file) => {
                      return (
                        <div
                          key={file.s3Key}
                          className={clsx(
                            "relative group cursor-pointer h-fit flex-0 rounded-lg duration-300 border-2",
                            file.name.match(fileType.image) ||
                              file.name.match(fileType.video)
                              ? "can-preview-upload"
                              : "default-file",
                            file.s3Key === selectedViewFiles.currentFile?.s3Key
                              ? " !border-blue-500"
                              : ""
                          )}
                          // onClick={preview}
                        >
                          {file.name.match(fileType.image) ? (
                            <div className="w-[4.5rem] h-[4.5rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
                              <div className="  text-fs-12 w-full h-full overflow-hidden  rounded-md">
                                <MeeyImage
                                  className="object-cover w-full h-full"
                                  src={file.uri}
                                  alt={file.name}
                                  resizeOptions={{ width: 0, height: 0 }}
                                />
                              </div>
                            </div>
                          ) : file.name.match(fileType.video) ? (
                            <div className="w-[4.5rem] h-[4.5rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer">
                              <div className="  text-fs-12 w-full h-full overflow-hidden  rounded-b-md">
                                <video src={file.url} />
                                <div className="absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2">
                                  <i className="mrv mrv-play_arrow text-white text-fs-24 font-bold"></i>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="w-[4.5rem] h-[4.5rem] bg-grey-200 rounded-md flex flex-col relative cursor-pointer border border-transparent border-dashed">
                              <div className="flex-[0_0_36px] w-full flex items-center">
                                <div className="-rotate-90 w-fit mr-2">
                                  <i className="mrv mrv-attachment text-fs-20 "></i>
                                </div>
                                <div>.{file.name.split(".")?.pop()}</div>
                              </div>
                              <div className="bg-grey-300 flex-[1_1_auto]  text-fs-12 py-1 px-2 overflow-hidden  rounded-b-md">
                                <span className="line-clamp-2">
                                  {file.name}
                                </span>
                              </div>
                            </div>
                          )}
                          <div
                            onClick={() => {
                              resetTransform();
                              setSelectedViewFiles({
                                ...selectedViewFiles,
                                currentFile: file,
                              });
                            }}
                            className="cursor-pointer absolute w-full h-full top-0 z-30 bg-[rgba(0,0,0,0.4)] flex items-center justify-center opacity-0 group-hover:opacity-100  rounded-md duration-300">
                            <i className="mrv mrv-preview text-white text-fs-20"></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            )}
          </TransformWrapper>
        </div>
      ) : null}
    </>
  ) : (
    <></>
  );
};

export default ModalViewDetailFileFollow;
