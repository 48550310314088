import clsx from "clsx";
import { ReactNode, useMemo, useState } from "react";

export interface IMeeyRateProps {
  numberOfStar?: number;
  value?: number;
  onChange?: (val: number) => void;
  itemClassName?: string;
  className?: string;
  disable?: boolean;
}
const MeeyRate = ({
  numberOfStar = 5,
  value = 0,
  onChange,
  className,
  itemClassName,
  disable = false,
}: IMeeyRateProps) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const renderStar: ReactNode[] = useMemo(() => {
    const array = [];
    for (let i = 0; i < numberOfStar; i++) {
      const isActveStar = i <= value - 1;
      array.push(
        <i
          onMouseMove={() => setHoverIndex(i)}
          onMouseLeave={() => setHoverIndex(-1)}
          key={i}
          className={clsx(
            "mrv mrv-star text-[40px] duration-200",
            isActveStar ? "text-[#FFC633]" : "text-grey-300",
            i <= hoverIndex && !disable
              ? "md:scale-[1.1] md:!text-[#FFC633]"
              : "",
            disable ? "cursor-default" : "cursor-pointer",
            itemClassName
          )}
          onClick={() => {
            if (disable) return;
            if (i === value - 1) onChange && onChange(0);
            else onChange && onChange(i + 1);
          }}></i>
      );
    }
    return array;
  }, [disable, hoverIndex, itemClassName, numberOfStar, onChange, value]);
  return <div className={clsx("flex", className)}>{renderStar}</div>;
};

export default MeeyRate;
