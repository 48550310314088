import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import REQUEST_METHOD from "@/constants/request-method";
import { BaseDataListResponse, ProjectReview } from "Models";
import { ProjectListingParams } from "Params";
import qs from "query-string";
import urlJoin from "url-join";

export const getListReviews = async (
  type: string,
  params: ProjectListingParams
) => {
  const path = urlJoin(
    `v1/projects/${type}`,
    `?${qs.stringify(params, { skipNull: true })}`
  );

  const response = await createFetcher<BaseDataListResponse<ProjectReview>>(
    () => ({
      apiUrl: MEEY_REVIEW_API_URL,
    })
  )(REQUEST_METHOD.GET, path, null, {});
  return response;
};
