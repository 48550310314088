import { useCallback, useEffect, useState } from "react";

export const useAnimateState = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: (e?: any) => void;
}) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (open)
      setTimeout(() => {
        setTrigger(true);
      }, 100);
  }, [open]);

  const triggerClose = useCallback(() => {
    setTrigger(false);
    setTimeout(() => {
      handleClose && handleClose();
    }, 300);
  }, [handleClose]);
  return {
    trigger,
    triggerClose,
  };
};
