// const   = dynamic(import("@cyntler/react-doc-viewer"), { ssr: false });
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import clsx from "clsx";

export interface IDocViewerPreviewProps {
  url: string;
  className?: string;
}
const DocViewerPreview = ({ url, className }: IDocViewerPreviewProps) => {
  return (
    <>
      <DocViewer
        className={clsx("h-full", className)}
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: url }]}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    </>
  );
};

export default DocViewerPreview;
