import UserTime from "@/components/UserTime";
import { FluctuationScoreEnum } from "@/constants/common-constant";
import { MEDIA_URL, STATIC_DOMAIN } from "@/constants/environment";
import { fileType } from "@/constants/file";
import { DEFAULT_USER_AVATAR } from "@/constants/image";
const ListFileReview = dynamic(import("@/modules/common/ListFileReview"), {
  ssr: false,
});
import { convertAddressNoStreet, resizeOnFlyUrl } from "@/utils";
import { ProjectReview } from "Models";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useMemo } from "react";

const HomeProjectCard = ({ data }: { data: ProjectReview }) => {
  const urlUserAvatar = useMemo(() => {
    if (data?.latestComment?.createdBy?.avatar) {
      return data?.latestComment?.createdBy?.avatar.includes(MEDIA_URL) &&
        data?.latestComment?.createdBy?.avatar.match(
          fileType.resizeImageSupport
        )
        ? resizeOnFlyUrl({
            uri: data?.latestComment?.createdBy?.avatar.replace(MEDIA_URL, ""),
            width: 32,
            height: 32,
            gravity: "ce",
            resizingType: "auto",
          })
        : data?.latestComment?.createdBy?.avatar;
    } else if (data?.latestComment?.nickName) {
      return null;
    } else return `${STATIC_DOMAIN}/${DEFAULT_USER_AVATAR}`;
  }, [data]);

  // const [isShowMore, setIsShowMore] = useState(false);
  // useEffect(() => {
  //   const reviewEle = document.getElementById(`review-${data?._id}`);
  //   if (!reviewEle) setIsShowMore(false);
  //   setIsShowMore(reviewEle?.scrollHeight > reviewEle?.offsetHeight);
  // }, [data]);

  return (
    <Link
      href={`/project/${data.slug}-${data.shortId ? data.shortId : data._id}`}>
      <div className=" gap-4 pt-2 pb-3 px-4 w-full bg-white cursor-pointer shadow hover:shadow-xl duration-300 shrink-0">
        <div className=" w-full border-b-2 border-dashed border-grey-300 pb-2 gap-3">
          <div className="text-base text-grey-800 font-semibold flex items-center gap-3">
            {data.title}
          </div>
          <div className="text-fs-12 text-grey-500 mt-0.5">
            {convertAddressNoStreet(data)}
          </div>
          <div className="flex items-center justify-between w-full gap-1 ">
            <div className="flex items-center gap-1">
              {data?.voteScore ? (
                <>
                  {data?.fluctuationScore == FluctuationScoreEnum.DOWN ? (
                    <i className="mrv mrv-trending_down text-red-600 text-fs-20"></i>
                  ) : null}
                  {data?.fluctuationScore == FluctuationScoreEnum.UP ? (
                    <i className="mrv mrv-trending_up text-green-500 text-fs-20"></i>
                  ) : null}
                  <span className="text-fs-14 text-grey-700 font-normal">
                    {Number.isInteger(data?.voteScore)
                      ? data?.voteScore
                      : data?.voteScore?.toFixed(1)}
                  </span>
                  <i className="mrv mrv-star text-[#FFC633] text-fs-20"></i>
                </>
              ) : null}
              {data?.totalHotReview ? (
                <span className="text-fs-14 text-grey-700 font-normal">
                  ({data?.totalHotReview} reviews)
                </span>
              ) : null}
            </div>
            {data?.totalView ? (
              <div className="text-grey-500 text-fs-14 flex items-center gap-1">
                <span>{data?.totalView}</span>
                <i className="mrv mrv-visibility text-grey-400 text-fs-20"></i>
              </div>
            ) : null}
          </div>
        </div>
        {data?.latestComment ? (
          <div className="mt-2">
            {data?.latestComment?.comment ? (
              <div
                className="text-fs-14 text-grey-700 line-clamp-2 break-word  w-full relative"
                id={`review-${data?._id}`}>
                “{data?.latestComment?.comment}” ”
              </div>
            ) : null}

            <ListFileReview data={data?.latestComment} maxFileShow={4} />
            <div className="flex mt-1.5 items-center gap-4">
              <UserTime
                src={urlUserAvatar}
                name={data?.latestComment?.nickName || "Ẩn danh"}
                className="w-fit"
                textClass="text-fs-12 text-grey-600"
                size={24}
                time={data?.latestComment?.publishedAt}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export default HomeProjectCard;
