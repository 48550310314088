import urlJoin from 'url-join'
import qs from 'query-string'
import { removePropertiesEmpty } from './object'
import { APP_BASE_URL } from '@/constants/environment'

export const getCanonicalUrl = (router: any) => {
	return (
		(APP_BASE_URL + (router.asPath === '/' ? '' : router.asPath)).split(
			'?'
		)[0] || ''
	)
}

export const generateCanonicalUrl = (canonicalUrl: string) =>
	`${APP_BASE_URL}/${canonicalUrl}`

export const getOriginUrl = (url: string) => {
	return url.split('?')[0] || ''
}

export const getPathWithSolidus = (path: string) => {
	return path.startsWith('/') ? path : `/${path}`
}

export const convertObjectToQueryString = (input: any): string => {
	if (!input) return ''
	const params = removePropertiesEmpty(input)
	let objectQueryString = {}
	Object.keys(params).map((key) => {
		if (typeof params[key] !== 'object' || Array.isArray(params[key])) {
			objectQueryString[key] = params[key]
		} else {
			objectQueryString[key] = qs.stringify(params[key] ?? {}, {
				arrayFormat: 'bracket-separator',
				skipEmptyString: true,
				skipNull: true,
			})
		}
	})

	return qs.stringify(objectQueryString, {
		skipNull: false,
		arrayFormat: 'separator',
	})
}

export const getUrlPage = (basePath: string, input: any): string => {
	const queryString = removePropertiesEmpty(input)
	return urlJoin(
		basePath,
		queryString && Object.keys(queryString).length
			? `?${convertObjectToQueryString(input)}`
			: ''
	)
}

export const getPath = (path: string, allowBaseUrl = true) => {
	const currentPath = getPathWithSolidus(path)
	return allowBaseUrl ? `${APP_BASE_URL}${currentPath}` : currentPath
}
