import React, { useContext } from "react";
import { HomeContext } from "@/context/home.context";
import FollowProjectCard from "./FollowProjectCard";
import ModalViewDetailFileFollow from "./ModalDetailFileFollow";
const ListFollowProject = () => {
  const { listFollowProject } = useContext(HomeContext);
  return (
    <>
      {listFollowProject?.results && listFollowProject?.results?.length ? (
        <>
          <div className="uppercase text-grey-700 text-fs-14 font-semibold mb-1 py-2 mt-3 px-4">
            🏢 dự án đang hóng
          </div>
          <div className="flex flex-nowrap overflow-auto gap-3 w-full px-4 pb-0.5 no-scrollbar">
            {listFollowProject?.results.map((item, index) => (
              <FollowProjectCard
                key={item?._id}
                reviewContent={item}
                hasManyItem={listFollowProject.results?.length > 1}
              />
            ))}
          </div>
          <ModalViewDetailFileFollow />
        </>
      ) : null}
    </>
  );
};

export default ListFollowProject;
