import MeeyImage from "@/components/MeeyImage";
import PopupAvatarUser from "@/components/PopupAvatarUser";
import { STATIC_DOMAIN } from "@/constants/environment";
import { useAuth } from "@/hooks/useAuth";
import { useState } from "react";
import ListScrollHotReview from "./ListScrollHotReview";
import SearchProject from "./SearchProject";
import NotiMobile from "@/components/PopupNotification/NotiMobile";

/* eslint-disable @next/next/no-img-element */
const HeaderHome = () => {
  const { user, login } = useAuth();
  const [openSearchProject, setOpenSearchProject] = useState(false);

  return (
    <>
      <div className="bg-header">
        <div className="flex justify-between px-4 pt-2.5">
          <div
            className="cursor-pointer"
            onClick={() => {
              window?.location?.reload();
            }}>
            <MeeyImage
              src={STATIC_DOMAIN + "/logo.svg"}
              alt="home"
              resizeOnFly={false}
              resizeOptions={{
                width: 114,
                height: 36,
              }}
            />
          </div>

          <div className="flex items-center gap-5">
            {/* <ModalAddReview /> */}
            {!user ? (
              <div
                className="w-9 h-9 flex items-center justify-center text-white cursor-pointer"
                onClick={login}>
                <i className="mrv mrv-account_circle text-fs-20"> </i>
              </div>
            ) : (
              <>
                <NotiMobile />
                <PopupAvatarUser />
              </>
            )}
          </div>
        </div>
        <div className=" mt-7 w-full px-4 pb-6">
          <h1 className="text-white text-fs-24 font-semibold mb-1">
            Review dự án
          </h1>
          <div className="text-fs-14 mb-5 text-white">
            Cập nhật liên tục Thông tin bên lề hấp dẫn của 5000 dự án trên toàn
            quốc
          </div>
          <div
            className=" flex items-center w-full bg-white p-1 rounded-lg px-3 py-2 text-grey-500"
            onClick={() => {
              setOpenSearchProject(true);
            }}>
            <i className="mrv mrv-search text-fs-20 mr-1"></i>
            <span>Tìm dự án mà bạn quan tâm....</span>
          </div>
        </div>
      </div>
      <ListScrollHotReview />
      {openSearchProject && (
        <SearchProject
          onClose={() => setOpenSearchProject(false)}
          open={openSearchProject}
        />
      )}
    </>
  );
};

export default HeaderHome;
