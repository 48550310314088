import { getListReviews } from "@/api/endpoint/reviews/get-list";
import { getListStormComment } from "@/api/endpoint/reviews/get-list-storm-comment";
import { getTopMembers } from "@/api/endpoint/reviews/get-top-members";
import { PAGE_SIZE, PAGE_SIZE_REVIEWS } from "@/constants/number";
import { DeviceContext } from "@/context/device.context";
import { HomeContextProvider } from "@/context/home.context";
//import Home from '@/modules/Desktop/Home'
//import HomeMobile from '@/modules/Mobile/Home'
import { getListTopAnimation } from "@/api/endpoint/reviews/get-list-topanimation";
import AppSeo from "@/components/Seo/AppSeo";
import HomeMobile from "@/modules/Mobile/Home";
import { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import { useContext, useEffect } from "react";
const Home = dynamic(() => import("@/modules/Desktop/Home"));
// const HomeMobile = dynamic(() => import('@/modules/Mobile/Home'))

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { query } = context;
  let params = {
    // skip: query?.page ? (Number(query?.page) - 1) * PAGE_SIZE : 0,
    limit: PAGE_SIZE,
    page: Number(query?.page ?? 1),
  };

  let paramsReviews = {
    limit: PAGE_SIZE_REVIEWS,
    page: Number(query?.page ?? 1),
  };

  // const listProject: BaseDataListResponse<Project> = await getListProject(
  //   params
  // );

  // const listReviews: BaseDataListResponse<ProjectReview> = await getListReviews(
  //   "lastest",
  //   paramsReviews
  // );

  // const listTopMember: BaseDataListResponse<TopMembers> = await getTopMembers();

  // const listStormComment: BaseDataListResponse<StormComment> =
  //   await getListStormComment();

  // const listFollowProject: any = await getListFollowProject(
  //   req?.cookies?.auth ? JSON.parse(req?.cookies?.auth)?.access_token : null
  // );

  const [
    // listProject,
    listReviews,
    listTopMember,
    listStormComment,
    // listFollowProject,
    listTopAnimation,
  ] = await Promise.allSettled([
    // await getListProject(params),
    await getListReviews("lastest", paramsReviews),
    await getTopMembers(),
    await getListStormComment(),
    // await getListFollowProject(
    //   req?.cookies?.auth ? JSON.parse(req?.cookies?.auth)?.access_token : null
    // ),
    await getListTopAnimation(),
  ]).then((results) =>
    results.map((result: any) => {
      return result.status === "fulfilled" ? result.value : null;
    })
  );

  return {
    props: {
      // listProject,
      listReviews,
      listTopMember,
      listStormComment,
      // listFollowProject,
      listTopAnimation,
      params,
    },
  };
}

export default function PageSlug(props: any) {
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <HomeContextProvider
      listTopAnimation={props.listTopAnimation}
      listProject={props.listProject}
      listReviews={props.listReviews}
      listTopMember={props.listTopMember}
      listStormComment={props.listStormComment}
      listFollowProject={props.listFollowProject}>
      <AppSeo />

      {isMobile ? <HomeMobile /> : <Home {...props} />}
    </HomeContextProvider>
  );
}
