import { createFetcher } from "@/api/utils/fetch-api";
import { MEEY_REVIEW_API_URL } from "@/constants/environment";
import REQUEST_METHOD from "@/constants/request-method";
import { ListFollowProject } from "Models";

export const getListFollowProject = async (
  token?: string
) => {
  const path = `v1/follows/user`
  const response = await createFetcher<ListFollowProject>(
    () => ({
      apiUrl: MEEY_REVIEW_API_URL,
    })
  )(REQUEST_METHOD.GET, path, null, {token});

  return response;
};
