import { Form } from "antd";
// import EmojiPicker from "emoji-picker-react";
import PopOver from "@/components/PopOver";
import UploadReviewImage from "@/components/ReviewInputComponent.tsx/UploadReviewFile";
import { DeviceContext } from "@/context/device.context";
import { exactCommentString } from "@/utils/html";
import EmojiData from "@emoji-mart/data";
import i18n from "@emoji-mart/data/i18n/vi.json";
import Picker from "@emoji-mart/react";
import clsx from "clsx";
import { useContext, useMemo, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

const InputReplyComponent = ({
  setLoading,
  label,
  loading,
  placeholder,
  requiredMessage,
  data,
}: {
  setLoading?: (val: boolean) => void;
  label?: string;
  loading?: boolean;
  placeholder?: string;
  requiredMessage?: string;
  data?: any;
}) => {
  const { isMobile } = useContext(DeviceContext);
  const form = Form.useFormInstance();
  const commentValue = Form.useWatch("comment", form);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const fileData = Form.useWatch("file", form);
  const [poinerSelect, setPointerSelect] = useState<{
    selectionStart: number;
    selectionEnd: number;
  }>({
    selectionStart: 0,
    selectionEnd: 0,
  });

  const replyLength = useMemo(() => {
    return commentValue
      ? exactCommentString(commentValue, [
          {
            userId: data.createdBy._id,
            nickName: data.nickName,
          },
        ])?.trim()?.length
      : 0;
  }, [commentValue, data.createdBy._id, data.nickName]);

  const metionsUser = {
    userId: data.createdBy._id,
    nickName: data.nickName || "",
  };

  return (
    <Form.Item
      label={label ?? "Phản hồi"}
      required
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}>
      <div
        className={clsx(
          "border px-3 pt-3 pb-1 rounded-md reply-review-input review-input",
          focus ? "ant-input ant-input-focused" : ""

          // hasError ? "border-red-500" : ""
        )}>
        <Form.Item
          name="comment"
          noStyle
          // validateTrigger={"onBlur"}
          rules={[
            {
              validator(rule, value, callback) {
                if (value?.trim()?.length) return Promise.resolve(true);
                else
                  return Promise.reject(
                    requiredMessage ?? "Vui lòng nhập nội dung phản hồi"
                  );
              },
            },
          ]}
          getValueFromEvent={(e) => {
            const realVal = exactCommentString(e.target.value, [metionsUser]);
            if (realVal.length <= 2000) return e.target.value;
            else
              return (e.target.value || "").slice(
                0,
                2000 + e.target.value?.length - realVal.length
              );
          }}
          validateFirst>
          <MentionsInput
            spellCheck={false}
            ref={inputRef}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              if (inputRef.current)
                setPointerSelect({
                  selectionEnd: inputRef.current?.inputElement.selectionEnd,
                  selectionStart: inputRef.current?.inputElement.selectionStart,
                });
              setFocus(false);
            }}
            placeholder="Nhập phản hồi"
            className="min-h-[8rem] text-base"
            disabled={loading}>
            <Mention
              style={{
                // color: "#3F82EE",
                backgroundColor: "#DCEBFB",

                // opacity: "100%",
                // position: "relative",
                // left: "-1px",
                // top: -1,
                // zIndex: 1,
              }}
              markup="@{{__id__||__display__}}"
              trigger={"@"}
              data={[
                {
                  id: data.createdBy._id,
                  display: data?.nickName || "anymos",
                },
              ]}
              displayTransform={(_id, display) => {
                // console.log(url);

                return `@${display === "anymos" ? "Ẩn danh" : display}`;
              }}
              renderSuggestion={(data) => (
                <div className="hidden">{data.display}</div>
              )}
            />
          </MentionsInput>
        </Form.Item>
        <div className="flex justify-between items-center">
          <div
            className={clsx(
              "flex w-full justify-between items-center",
              loading || fileData?.length > 0 ? "flex-col" : ""
            )}>
            <Form.Item name="file" noStyle>
              <UploadReviewImage
                setLoading={setLoading}
                uploadButtonProps={
                  <div>
                    <i className="mrv mrv-add_photo_alternate text-fs-18 text-blue-400"></i>
                  </div>
                }
                className={
                  loading || fileData?.length > 0 ? "w-full" : "!w-auto"
                }
                loading={loading}
              />
            </Form.Item>
            <div
              className={
                isMobile ? "" : "flex justify-between items-center w-full"
              }>
              {isMobile ? null : (
                <PopOver
                  position={"left-top"}
                  content={
                    <Picker
                      data={EmojiData}
                      onEmojiSelect={async (value) => {
                        const textarea = inputRef.current?.inputElement;
                        const mentionValue = data?.nickName
                          ? `@{{${data.createdBy._id}||${data?.nickName}}}`
                          : `@{{${data.createdBy._id}||anymos}}`;
                        const mentionShow = data?.nickName
                          ? `@${data?.nickName}`
                          : `@Ẩn danh`;
                        const index = commentValue.indexOf(mentionValue);
                        if (
                          (poinerSelect.selectionStart - index) *
                            (poinerSelect.selectionEnd *
                              (index + mentionValue.length)) <
                          0
                        )
                          return;
                        let start;
                        let end;
                        if (poinerSelect.selectionStart < index) {
                          start = commentValue.substring(
                            0,
                            poinerSelect.selectionStart
                          );
                          end = commentValue.substring(
                            poinerSelect.selectionEnd
                          );
                        } else {
                          start = commentValue.substring(
                            0,
                            poinerSelect.selectionStart +
                              mentionValue.length -
                              mentionShow.length
                          );
                          end = commentValue.substring(
                            poinerSelect.selectionEnd +
                              mentionValue.length -
                              mentionShow.length
                          );
                        }

                        const text = start + value.native + end;
                        if (
                          exactCommentString(text, [metionsUser]).length < 2000
                        ) {
                          form.setFieldValue("comment", text);
                          form.validateFields(["comment"]);
                          textarea.focus();
                          await Promise.resolve();
                          textarea.selectionStart = textarea.selectionEnd =
                            poinerSelect.selectionStart + value.native.length;
                        }
                      }}
                      i18n={i18n}
                    />
                  }
                  trigger={"click"}>
                  <button
                    className="p-2 cursor-pointer text-grey-400"
                    type="button"
                    disabled={loading}>
                    <i className="mrv mrv-sentiment_very_satisfied text-fs-18 text-orange-400"></i>
                  </button>
                </PopOver>
              )}
              <span className="text-[11px] text-grey-400">
                {replyLength} / 2000
              </span>
            </div>
          </div>
        </div>
      </div>
    </Form.Item>
  );
};

export default InputReplyComponent;
