import * as React from 'react'
import { NextSeo, NextSeoProps } from 'next-seo'
// import { useRouter } from 'next/router'
// import { NO_SEO_ROBOTS_CONTENT } from '@/constants/environment'

export interface HeadProps {
	title: string
	metaDescription?: string
	metaKeywords?: string
	metaTitle?: string
	description?: string
	images?: any
}
const Head = ({ title = 'Meeyhome', ...rest }: NextSeoProps) => {
	// const router = useRouter()
	// const isRouterHasQuery = router.query.amp
	// 	? false
	// 	: router.asPath.includes('?')
	return (
		<NextSeo
			title={title}
			//noindex={NO_SEO_ROBOTS_CONTENT || isRouterHasQuery ? true : rest.noindex}
			{...rest}
		/>
	)
}

export default Head
