import Head from '@/components/Head'
import { APP_BASE_URL, NO_SEO_ROBOTS_CONTENT } from '@/constants/environment'
import { getOriginUrl } from '@/extensions/route'
import { useRouter } from 'next/router'
import { BaseMetaSeo } from './base'

const AppSeo = () => {
	const router = useRouter()

	const canonicalUrl =
		APP_BASE_URL + getOriginUrl(router.asPath === '/' ? '' : router.asPath)

	return (
		<Head
			title={BaseMetaSeo.metaTitle}
			description={BaseMetaSeo.metaDescription}
			openGraph={BaseMetaSeo.openGraph}
			additionalMetaTags={BaseMetaSeo.additionalMetaTags}
			canonical={canonicalUrl}
			noindex={NO_SEO_ROBOTS_CONTENT}
			nofollow={NO_SEO_ROBOTS_CONTENT}
		/>
	)
}

export default AppSeo
