import MeeyImage from "@/components/MeeyImage";
import UserTime from "@/components/UserTime";
import MeeyRate from "@/components/MeeyRate";
import { convertAddressNoStreet } from "@/utils";
import { useMemo, useState } from "react";
import { DEFAULT_USER_AVATAR } from "@/constants/image";
import { STATIC_DOMAIN } from "@/constants/environment";
import { CommentTypeEnum } from "@/constants/enum/comment";
import ModalReplyFollowProject from "./ModalReplyFollowProject";
import { ResultListFollowProject } from "Models";
import { DEFAULT_LOGO_PROJECT } from "@/constants/project-constant";
import Link from "next/link";
import clsx from "clsx";
import ReviewShowTextContent from "@/components/ReviewShowTextContent";
import ListFileReview from "@/modules/common/ListFileReview";
import Button from "@/components/Button";
interface IFollowProjectCard {
  reviewContent?: ResultListFollowProject;
  hasManyItem?: boolean;
}
const FollowProjectCard = ({
  reviewContent,
  hasManyItem,
}: IFollowProjectCard) => {
  const [openModalReply, setOpenModalReply] = useState(false);

  const urlUserAvatar = useMemo(() => {
    if (reviewContent?.topreview?.createdBy?.avatar) {
      return reviewContent?.topreview?.createdBy?.avatar;
    } else if (reviewContent?.topreview?.nickName) {
      return null;
    } else return `${STATIC_DOMAIN}/${DEFAULT_USER_AVATAR}`;
  }, [reviewContent]);

  const userAction = useMemo(() => {
    switch (reviewContent?.topreview?.type) {
      case CommentTypeEnum.QUESTION:
        return {
          title: "đã hỏi",
          icon: (
            <i className="mrv mrv-contact_support text-fs-20 leading-5 text-grey-400"></i>
          ),
          buttonTitle: "Trả lời ngay",
        };
      case CommentTypeEnum.REVIEW:
        return {
          title: "đã viết review",
          icon: (
            <i className="mrv mrv-review text-fs-20 leading-5 text-grey-400"></i>
          ),
          buttonTitle: "Phản hồi",
        };
      default:
        return {
          title: "đã phản hồi",
          icon: null,
          buttonTitle: null,
        };
    }
  }, [reviewContent]);

  return (
    <div
      className={clsx(
        "gap-4 p-3 w-full bg-white rounded-lg  shadow hover:shadow-xl duration-300 ",
        hasManyItem ? "flex-[0_0_95%]" : "flex-[0_0_auto]",
        openModalReply ? "" : "cursor-pointer"
      )}
    >
      <div className="pb-2 border-b border-b-grey-200">
        <div className="flex gap-2 mb-2">
          <div className="w-9 h-9 relative flex-0 rounded-xl">
            <MeeyImage
              src={
                reviewContent.logo
                  ? reviewContent.logo.uri
                  : `${STATIC_DOMAIN}/${DEFAULT_LOGO_PROJECT}`
              }
              alt={reviewContent.title}
              resizeOnFly={reviewContent.logo ? true : false}
              resizeOptions={{
                width: 0,
                height: 108,
              }}
              className="w-full h-full absolute object-contain rounded-md"
            />
          </div>
          <div className="flex-1 justify-between items-center w-full  ">
            <Link
              href={`/project/${reviewContent?.slug}-${
                reviewContent?.shortId
                  ? reviewContent?.shortId
                  : reviewContent?._id
              }`}
            >
              <div className="text-fs-14 text-grey-700 font-semibold line-clamp-1 w-full break-all">
                {reviewContent?.title}{" "}
              </div>
            </Link>
            <div className="text-[11px] text-grey-500 mt-0.5">
              {convertAddressNoStreet(reviewContent)}
            </div>
          </div>
        </div>
        {reviewContent?.voteScore ? (
          <div className="flex items-center gap-1 h-4">
            <div className="text-fs-14 text-grey-700 font-semibold mt-[2px]">
              {reviewContent?.voteScore
                ? reviewContent?.voteScore?.toFixed(1)
                : 0}
            </div>
            <MeeyRate
              disable={true}
              value={reviewContent?.voteScore}
              itemClassName="!text-fs-20"
            />
          </div>
        ) : null}
      </div>

      <div className="flex-1">
        <div className=" mt-2.5 items-center gap-4">
          <UserTime
            src={urlUserAvatar}
            name={reviewContent?.topreview?.nickName || "Ẩn danh"}
            className="w-fit"
            imageClass="!w-8 !h-8"
            textClass="text-fs-14 text-grey-600 line-clamp-1"
            size={32}
            time={reviewContent?.topreview?.publishedAt}
            haveAction={true}
            actionTitle={userAction.title}
            actionIcon={userAction.icon}
            maxLengthName={15}
          />
        </div>

        {reviewContent?.topreview?.comment ? (
          <div className="mt-2 text-fs-14 text-grey-700 break-word">
            “
            <ReviewShowTextContent
              content={reviewContent?.topreview?.comment}
              limit={200}
            />
            {reviewContent?.topreview?.comment?.length > 200 ? " ..." : null}”
          </div>
        ) : null}
        <div
          onClick={() => {
            if (!reviewContent?.topreview?.isReply) {
              setOpenModalReply(true);
            }
          }}
        >
          <ListFileReview data={reviewContent?.topreview} maxFileShow={4} />
        </div>
        {reviewContent?.topreview?.isReply ? (
          <div className="h-9 w-full flex justify-end">
            {reviewContent?.topreview?.type == CommentTypeEnum.REVIEW ? (
              <div className="flex items-center gap-1">
                <span className="text-fs-14 text-grey-600 font-medium">
                  Đã phản hồi
                </span>
                <i className="mrv mrv-check_circle text-fs-18 text-green-500"></i>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <span className="text-fs-14 text-grey-600 font-medium">
                  Đã trả lời
                </span>
                <i className="mrv mrv-check_circle text-fs-18 text-green-500"></i>
              </div>
            )}
          </div>
        ) : reviewContent?.topreview?.type != CommentTypeEnum.REPLY ? (
          <div className="w-full flex justify-end mt-2 h-9">
            <Button
              btnType="filled"
              onClick={() => setOpenModalReply(true)}
              className={clsx(
                "w-[106px] !border-none p-0 text-fs-14 font-medium flex justify-center",
                reviewContent?.topreview?.type == CommentTypeEnum.REVIEW
                  ? "!bg-blue-500"
                  : "!bg-purple-500"
              )}
            >
              {userAction.buttonTitle}
            </Button>
          </div>
        ) : null}
        {openModalReply ? (
          <ModalReplyFollowProject
            open={openModalReply}
            onClose={() => setOpenModalReply(false)}
            reviewContent={reviewContent}
            userAction={userAction}
            urlUserAvatar={urlUserAvatar}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FollowProjectCard;
