import { DEFAULT_IMAGE_URI, RESIZE_IMAGE } from "@/constants/image";
import {
  APP_BASE_URL,
  APP_DOMAIN,
  FB_APP_ID,
  STATIC_DOMAIN,
} from "@/constants/environment";
import { resizeOnFlyUrl } from "@/utils/image";

const metaTitle = "Meey Review - Diễn đàn đánh giá đa chiều bất động sản dự án";
const metaDescription = `Minh bạch hóa thông tin về các dự án bất động sản thông qua đánh giá đa chiều có cơ sở của khách hàng theo thời gian thực nhằm bảo vệ quyền và lợi ích của các bên liên quan trong chuỗi phát triển bất động sản.`;
const metaKeyword = "mua bán cho thuê nhà đất - bất động sản";
const urlImage = resizeOnFlyUrl({
  uri: DEFAULT_IMAGE_URI,
  width: RESIZE_IMAGE.default.width,
  height: RESIZE_IMAGE.default.height,
  resizingType: "fill",
  gravity: "no",
});

const baseImage = {
  url: `${STATIC_DOMAIN}/images/thumb_image.png`,
  description: null,
  name: "capture-meeyhome.jpg",
  mimeType: "image/jpeg",
  width: RESIZE_IMAGE.default.width,
  height: RESIZE_IMAGE.default.height,
  alt: "Trang chủ",
};

export const BaseMetaSeo = {
  baseUrlImage: urlImage,
  metaTitle: metaTitle,
  metaDescription: metaDescription,
  metaKeyword: metaKeyword,
  siteName: APP_DOMAIN,
  locale: "vi_VN",
  canonical: APP_BASE_URL,
  baseImage: baseImage,
  openGraph: {
    title: metaTitle,
    description: metaDescription,
    images: [baseImage],
    site_name: APP_DOMAIN,
    locale: "vi_VN",
    url: APP_BASE_URL,
    type: "website",
    article: {
      authors: ["https://www.facebook.com/meeyland.global/"],
    },
  },
  facebook: { appId: FB_APP_ID },
  twitter: { site: "@meey_review" },
  additionalMetaTags: [
    {
      name: "keywords",
      content: metaKeyword ?? "",
    },
    {
      property: "fb:pages",
      content: "148873268971895",
    },
    {
      property: "article:author",
      content: "https://www.facebook.com/meeyland.global/",
    },
    {
      property: "twitter:creator",
      content: "@meey_review",
    },
    {
      property: "twitter:title",
      content: metaTitle || "",
    },
    {
      property: "twitter:description",
      content: metaDescription || "",
    },
    {
      property: "twitter:image",
      content: urlImage,
    },
    {
      property: "twitter:image:width",
      content: `${RESIZE_IMAGE.default.width}`,
    },
    {
      property: "twitter:image:height",
      content: `${RESIZE_IMAGE.default.height}`,
    },
    {
      property: "og:locale:alternate",
      content: "vi_VN",
    },
  ],
  siteLinkSearchBox: {
    type: "Website",
    name: "Meey Review",
    url: APP_BASE_URL,
  },
};
