import MeeyImage from "@/components/MeeyImage";
import MeeyRate from "@/components/MeeyRate";
import { DEFAULT_IMAGE_URI } from "@/constants/image";
import { ProjectReview } from "Models";
import Link from "next/link";

interface ISeenProjectCard {
  item?: {
    project?: ProjectReview;
  };
}
const SeenProjectCard = ({ item }: ISeenProjectCard) => {
  return (
    <Link
      href={`/project/${item.project.slug}-${item.project.shortId}`}
      className="flex-[0_0_95%] gap-4 p-2 w-full bg-white rounded-lg duration-300 cursor-pointer">
      <MeeyImage
        src={item.project?.banner?.uri ?? DEFAULT_IMAGE_URI}
        resizeOptions={{
          width: 600,
          height: 92,
        }}
        alt="project seen"
        className="rounded-lg object-cover w-full h-[92px]"
      />
      <div className="text-grey-800 font-semibold mt-2 line-clamp-1">
        {item.project.title}
      </div>
      <div className="flex gap-1 items-center">
        {item.project.voteScore ? (
          <>
            <div className="text-fs-14 text-grey-800">
              {item.project.voteScore?.toFixed(1)}
            </div>
            <MeeyRate
              disable
              itemClassName="!text-fs-24"
              value={Math.floor(item.project.voteScore)}
            />
          </>
        ) : null}
        {item.project.totalHotReview ? (
          <div className="text-fs-14 text-grey-800">
            ({item.project.totalHotReview} reviews)
          </div>
        ) : null}
      </div>
    </Link>
  );
};

export default SeenProjectCard;
