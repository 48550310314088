import { urlRegex } from "@/constants/common-constant";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";

export interface IMeeyRateProps {
  content?: string;
  mentions?: {
    userId: string;
    nickName?: string;
  }[];
  limit?: number;
  className?: string;
  searchString?: string;
}
const ReviewShowTextContent = ({
  className,
  content,
  mentions,
  limit,
  searchString,
}: IMeeyRateProps) => {
  const htmlContent = useMemo(() => {
    let rawContentString = content;
    mentions?.forEach((mention) => {
      rawContentString = rawContentString
        .replaceAll(
          `@{{${mention.userId}||${mention.nickName}}}`,
          `@${mention.nickName}`
        )
        .replaceAll(`@{{${mention.userId}||anymos}}`, `@Ẩn danh`);
    });

    if ((limit || rawContentString.length <= limit) && !searchString) {
      const needReplaceItems: {
        index: number;
        rootData: string;
        indexInRawData: number;
        showRawData: string;
        replaceData: (len?: number) => string;
      }[] = [];

      let lastedIndexFind = 0;
      let lastedIndexRawFind = 0;

      mentions?.forEach((mention) => {
        const oldData = mention.nickName
          ? `@{{${mention.userId}||${mention.nickName}}}`
          : `@{{${mention.userId}||anymos}}`;
        const replaceData = `@${
          mention.nickName ? mention.nickName : "Ẩn danh"
        }`;
        // const regex = new RegExp()
        const indexFind = content.indexOf(oldData, lastedIndexFind);
        const indexRawFind = rawContentString.indexOf(
          replaceData,
          lastedIndexRawFind
        );
        if (indexFind >= 0) {
          needReplaceItems.push({
            index: indexFind,
            rootData: oldData,
            indexInRawData: indexRawFind,
            showRawData: replaceData,
            replaceData: (len = null) =>
              `<span class="text-blue-500 cursor-pointer">${
                len ? replaceData.slice(0, len) : replaceData
              } </span>`,
          });
          lastedIndexFind = indexFind;
          lastedIndexRawFind = indexRawFind + 1;
        }
      });

      let match;
      let lastedIndexRawFindLink = 0;
      while ((match = urlRegex.exec(content))) {
        const indexLink = match.index;
        const link = match[0];
        const indexRawFindLink = rawContentString.indexOf(
          link,
          lastedIndexRawFindLink
        );
        needReplaceItems.push({
          index: indexLink,
          rootData: link,
          showRawData: link,
          indexInRawData: indexRawFindLink,
          replaceData: (len = null) =>
            `<a href="${link}" title="${link}" class="w-auto break-all" target="_blank">${
              len ? link.slice(0, len) : link
            }</a>`,
        });
        lastedIndexRawFindLink = indexRawFindLink + 1;
      }

      needReplaceItems.sort((a, b) => a.indexInRawData - b.indexInRawData);
      let returnString = "";
      let currentLen = returnString.length;
      if (needReplaceItems.length) {
        for (let id = 0; id < needReplaceItems.length; id++) {
          const item = needReplaceItems[id];
          // console.log(needReplaceItems, item.indexInRawData);

          if (currentLen < limit)
            if (item.indexInRawData <= limit) {
              if (item.showRawData.length + item.indexInRawData >= limit) {
                const concatString =
                  content.slice(
                    id === 0
                      ? 0
                      : needReplaceItems[id - 1].index +
                          needReplaceItems[id - 1].rootData.length,
                    item.index
                  ) + item.replaceData(limit - item.indexInRawData);
                returnString += concatString;

                currentLen +=
                  limit -
                  (id === 0
                    ? 0
                    : needReplaceItems[id - 1].indexInRawData +
                      needReplaceItems[id - 1].showRawData.length);
              } else {
                const concatString =
                  content.slice(
                    id === 0
                      ? 0
                      : needReplaceItems[id - 1].index +
                          needReplaceItems[id - 1].rootData.length,
                    item.index
                  ) + item.replaceData();
                returnString += concatString;
                currentLen +=
                  item.indexInRawData -
                  (id === 0
                    ? 0
                    : needReplaceItems[id - 1].indexInRawData +
                      needReplaceItems[id - 1].showRawData.length) +
                  item.showRawData.length;
              }
            } else {
              const concatString = content.slice(
                id === 0
                  ? 0
                  : needReplaceItems[id - 1].index +
                      needReplaceItems[id - 1].rootData.length,
                limit - currentLen
              );

              returnString += concatString;
              currentLen +=
                limit -
                currentLen -
                (id === 0
                  ? 0
                  : needReplaceItems[id - 1].indexInRawData +
                    needReplaceItems[id - 1].showRawData.length);
            }
        }

        if (currentLen < limit) {
          const lastedItem = needReplaceItems[needReplaceItems.length - 1];
          returnString += content.slice(
            lastedItem.index + lastedItem.rootData.length,
            limit - currentLen + lastedItem.index + lastedItem.rootData.length
          );
        }
      } else returnString = content.slice(0, limit);

      return returnString;
    } else {
      let contentString = content;
      const searchRegex = new RegExp(searchString, "ig");
      if (searchString) {
        contentString = contentString.replaceAll(
          searchRegex,
          (substring, index, data) => {
            const listLink = contentString.match(urlRegex);

            let mark = false;
            mentions.forEach((mention) => {
              const mentionString = mention.nickName
                ? `@{{${mention.userId}||${mention.nickName}}}`
                : `@{{${mention.userId}||anymos}}`;
              let startIndexFind = -1;
              do {
                const find = contentString.indexOf(
                  mentionString,
                  startIndexFind + 1
                );
                if (find > -1) {
                  if (find <= index && index < find + mentionString.length)
                    mark = true;
                  startIndexFind = find + mentionString.length;
                } else startIndexFind = find;

                // debugger;
              } while (startIndexFind >= 0);
            });

            if (mark) return substring;
            return listLink?.find((e) => e.indexOf(substring) >= 0)
              ? substring
              : `<span class="bg-[rgba(255,198,51,0.30)] cursor-pointer">${substring}</span>`;
          }
        );
      }
      mentions?.forEach((mention) => {
        rawContentString = rawContentString
          .replaceAll(
            `@{{${mention.userId}||${mention.nickName}}}`,
            `${mention.nickName}`
          )
          .replaceAll(`@{{${mention.userId}||anymos}}`, `@Ẩn danh`);

        contentString = contentString
          .replaceAll(
            `@{{${mention.userId}||${mention.nickName}}}`,
            `<span class="text-blue-500 cursor-pointer">@${mention.nickName} </span>`
          )
          .replaceAll(
            `@{{${mention.userId}||anymos}}`,
            `<span class="text-blue-500 cursor-pointer">@Ẩn danh </span>`
          );
      });
      // handle link
      contentString = contentString.replace(urlRegex, function (url) {
        return `<a href="${url}" title="${url}" class="w-auto break-all" target="_blank">${url.replaceAll(searchRegex, (substring) => `<span class="bg-[rgba(255,198,51,0.30)] cursor-pointer">${substring}</span>`)}</a>`;
      });

      return contentString;
    }
  }, [content, limit, mentions, searchString]);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <span
      className={clsx("whitespace-pre-wrap", className)}
      dangerouslySetInnerHTML={{
        __html: mounted ? htmlContent : content,
      }}></span>
  );
};

export default ReviewShowTextContent;
